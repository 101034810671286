const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
    visibility: false
}
const sidebarVisibility = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setVisibility: (state, action) => {
            state.visibility = action.payload
        }
    }
})

export const { setVisibility } = sidebarVisibility.actions
export default sidebarVisibility.reducer
