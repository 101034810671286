import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    progress: 0
}

const loadingSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            console.log('action', state, action.payload)
            state.progress = action.payload
        }
    }
})

export const { setLoading } = loadingSlice.actions

export default loadingSlice.reducer
