// ** Reducers Imports
import auth from './slices/auth'
import layout from './slices/layout'
import navbar from './slices/navbar'
import utils from './slices/utils'
import loader from './slices/loading'
import sidebar from './slices/SidebarVisibility'
const rootReducer = {
    auth,
    navbar,
    layout,
    utils,
    loader,
    sidebar
}

export default rootReducer
