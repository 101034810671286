// ** React Imports
import { Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// ** Redux Imports
import { store } from './redux/store'
import { Provider } from 'react-redux'

// ** ThemeColors Context

import { ThemeContext } from './utility/context/ThemeColors'

// ** Mui Theme
import { createTheme, ThemeProvider } from '@mui/material/styles'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './assets/fonts/feather/iconfont.css'
import './index.css'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** sentry import
import * as Sentry from '@sentry/react'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { AbilityContext } from './utility/context/Can'
import ability from './configs/acl/ability'
import { ConfigProvider } from 'antd'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const container = document.getElementById('root')
const root = createRoot(container)

// change mui theme_color
const outerTheme = createTheme({
    palette: {
        primary: {
            main: '#EA2224'
        }
    }
})

// ** Sentry Init
Sentry.init({
    dsn: 'https://9995e19bf8590fb52bb98fc7336258a2@o4505754337017856.ingest.sentry.io/4505782246572032',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                'http://localhost:3000',
                'https://vision-transport2.onrender.com',
                'https://visiontransport.vercel.app',
                'https://vision.up.railway.app',
                'https://visiontransport-production.onrender.com'
            ]
        }),
        new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

root.render(
    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <Provider store={store}>
                <Suspense fallback={<Spinner />}>
                    <AbilityContext.Provider value={ability}>
                        <ThemeProvider theme={outerTheme}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        // Seed Token
                                        colorPrimary: '#EA2224',
                                        borderRadius: 0,
                                        controlHeightLG: 38 // size = large
                                    }
                                }}
                            >
                                <ThemeContext>
                                    <LazyApp />
                                    <Toaster
                                        position={themeConfig.layout.toastPosition}
                                        toastOptions={{ className: 'react-hot-toast' }}
                                    />
                                </ThemeContext>
                            </ConfigProvider>
                        </ThemeProvider>
                    </AbilityContext.Provider>
                </Suspense>
            </Provider>
        </BrowserRouter>
    </I18nextProvider>
)

serviceWorker.unregister()

/*** very importand public folder and <html lang="en" dir="ltr"> for apply bootstrap & scss style apply ***/
