/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// ** import json language
import enTranslation from './lang/en.json';
import taTranslation from './lang/ms.json';


i18n.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslation },
        ms: { translation: taTranslation },
    },
    lng: localStorage.getItem("preferred_language"), // Set the default language
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
