import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: {},
  access: {}
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload
    },
    logout(state) {
      localStorage.removeItem("userToken")
      state.user = {}
    },
    setAccess(state, action) {
      state.access = action.payload
    }
  }
})

export const { login, logout, setAccess } = authSlice.actions
export default authSlice.reducer
