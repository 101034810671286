import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    alertModel: false,
    createProps: null,
    isCreatePopup: false,
    id: null,
    csvDetails: [],
    row_id: '',
    emp_status: false,
    dedicatedPayload: {},
    docs_data: '',
    files_data: '',
    modalTitle: '',
    dataLoader:false
}

const utilSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        setAlertModel(state, action) {
            state.alertModel = action.payload
        },
        setCreateOptions(state, action) {
            state.createProps = action.payload
        },
        setCreatePopup(state, action) {
            state.isCreatePopup = action.payload
        },
        setID(state, action) {
            state.id = action.payload
        },
        setCsvDetails(state, action) {
            state.csvDetails = action.payload
        },
        setRowId(state, action) {
            state.row_id = action.payload
        },
        setEmpStatus(state, action) {
            state.emp_status = action.payload
        },
        setDocsData(state, action) {
            state.docs_data = action.payload
        },
        setFilesData(state, action) {
            state.files_data = action.payload
        },
        setDedicatedPayload(state, action) {
            state.dedicatedPayload = action.payload
        },
        setBusinessName: (state, action) => {
            state.modalTitle = action.payload
        },
        setDataLoader: (state, action) => {
            state.dataLoader = action.payload
        }
    }
})

export const {
    setAlertModel,
    setCreateOptions,
    setCreatePopup,
    setID,
    setCsvDetails,
    setRowId,
    setEmpStatus,
    setDedicatedPayload,
    setDocsData,
    setFilesData,
    setBusinessName,
    setDataLoader
} = utilSlice.actions
export default utilSlice.reducer
