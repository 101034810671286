/* eslint-disable */
// ** Logo
import logo from '@src/assets/logo/logo.svg'
import logoAdinn from '@src/assets/image/logoAdinn.png'

// ** import config
import { env } from '../../../configs/env'

const SpinnerComponent = () => {
    return (
        <div className="fallback-spinner app-loader">
            {env.REACT_APP_FMS_MODE == 'true' ? (
                <img
                    className="fallback-logo"
                    style={{
                        height: '100px',
                        width: '284px'
                    }}
                    src={logoAdinn}
                    alt="Adinn Logo"
                />
            ) : (
                <img className="fallback-logo" src={logo} alt="logo" />
            )}

            <div className="loading">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
            </div>
        </div>
    )
}

export default SpinnerComponent
