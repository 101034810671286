export const env = {
    REACT_APP_STATE_API: process.env.REACT_APP_STATE_API,
    REACT_APP_PDF_SERVER: process.env.REACT_APP_PDF_SERVER,
    REACT_APP_STATE_LIST: process.env.REACT_APP_STATE_LIST,
    REACT_APP_SERVER_URL: process.env.REACT_APP_SERVER_URL,
    NODE_ENV: process.env.NODE_ENV,
    REACT_APP_FMS_MODE: process.env.REACT_APP_FMS_MODE,
    REACT_APP_GPS_SERVER: process.env.REACT_APP_GPS_SERVER,
    GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY || 'AIzaSyBWuJuDvuB7deQbracipj35tBlI3cJS4aM'
}
